<template>
  <div id="wallet-screen">
    <jumbotron
      :logoSrc="'/images/logos/bb-logo-wtext-white.svg'"
      :description="$t('walletScreen.welcomeComponent.description')"
      :storesLabel="$t('walletScreen.welcomeComponent.downloadText')"
      :backgroundImage="'/images/screens/wallet/desktop/header_image.jpg'"
      :mobileBackgroundImage="'/images/screens/wallet/mobile/wallet_header.jpg'"
      :background="'#4B2EAA'"
      :textColor="'#fff'"
      :imgSrc="'/images/elements/home/web/home_header_phone.png'"
      :imageAboveStoreImg="
        '/images/elements/home/web/home_header_phone.png'
      "
      mobileHeight="1007"
    />
    <wallet-info-component />
    <wallet-mobile-suggester-component />
    <wallet-web-suggester-component />
  </div>
</template>

<script>
import WalletInfoComponent from "@/components/modules/wallet/WalletInfoComponent";
import WalletMobileSuggesterComponent from "@/components/modules/wallet/WalletMobileSuggesterComponent";
import WalletWebSuggesterComponent from "@/components/modules/wallet/WalletWebSuggesterComponent";

export default {
  name: "WalletScreen",
  props: {},
  components: {
    WalletInfoComponent,
    WalletMobileSuggesterComponent,
    WalletWebSuggesterComponent,
  },
  data: () => ({}),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.jumbotron {
  &::v-deep {
    .illustration {
      margin-left: 0 !important;
      position: absolute;
      bottom: -140px;
  
      @media (max-width: $breakpoint-md) {
        bottom: -100px;
      }
  
      @media (max-width: $breakpoint-sm) {
        background-position: top !important;
        
        .bottom-section {
          margin-bottom: $margin-m;
        }
      }
    }
    .illustration-above-store-img {
      max-height: 370px;
      margin-top: 90px;
      margin-bottom: $margin-m;
      img {
        max-height: 370px;
      }
    }
  }
}

</style>
