<template>
  <div id="wallet-info">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="description text-l mx-auto">
            {{ $t("walletScreen.info.description") }}
          </div>
          <div class="mobile-component-wrapper">
            <mobile-screens-menu />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MobileScreensMenu from "@/components/elements/MobileScreensMenu";

export default {
  name: "WalletInfo",
  props: {},
  components: {
    MobileScreensMenu,
  },
  data: () => ({}),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
#wallet-info {
  padding-top: 200px;
  padding-bottom: 15px;
  text-align: center;
  .description {
    color: $text-blue;
    margin-bottom: 80px;
    max-width: 723px;
  }
  .mobile-component-wrapper {
    margin: 0 0 60px;

    @media screen and (max-width: 574px) {
      margin: 0 0 30px;
    }
  }
}
@media (max-width: $breakpoint-xs) {
  #wallet-info {
    padding-top: $margin-s;
  }
}
</style>
