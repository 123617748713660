<template>
  <div class="mobile-screen-menu">
    <div class="module-container">
      <div class="mobile-menu-wrapper" v-on-clickaway="handleMobileMenuBlur">
        <div class="selected" @click="openMobileMenu">
          <span class="selected-text">{{ selectedMenuItem }}</span>
          <span class="menu-icon" :class="isMobileMenuOpen ? 'opened' : '' ">
            <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 6.357C6.69166 6.357 6.39166 6.25408 6.15 6.04033L1.15833 1.66241C0.908332 1.44075 0.891665 1.06075 1.125 0.823247C1.35833 0.585747 1.75833 0.569914 2.00833 0.79158L7 5.1695L11.9917 0.79158C12.2417 0.569914 12.6417 0.585747 12.875 0.823247C13.1083 1.06075 13.0917 1.44075 12.8417 1.66241L7.85 6.04033C7.60833 6.25408 7.30833 6.357 7 6.357Z" fill="#4B2EAA" fill-opacity="0.7"/>
            </svg>
          </span>
        </div>
        <ul :class="['menu-list', { opened: isMobileMenuOpen }]">
          <li
            :class="['menu-item', { active: index == currentScreenIndex }]"
            v-for="(item, index) in menuItems"
            :key="index"
            @click="selectItem(index)"
          >
            {{ item.menuTitle }}
          </li>
        </ul>
      </div>
      <div class="mobile-wrapper">
        <img class="device-img" src="/images/elements/empty_phone.png" />
        <transition
          mode="out-in"
          name="fade"
          enter-active-class="animate__animated animate__fadeIn animate__fast"
          leave-active-class="animate__animated animate__fadeOut  animate__fast"
        >
          <img
            v-if="currentScreenIndex !== undefined"
            class="current-screen"
            :src="currentScreen"
          />
        </transition>
      </div>
      <div class="menu-wrapper">
        <ul class="menu-list">
          <li
            :class="['menu-item', { active: index == currentScreenIndex }]"
            v-for="(item, index) in menuItems"
            :key="index"
            @mouseover="setCurrentScreen(index)"
          >
            {{ item.menuTitle }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
// import ArrowDown from '@/components/shared/icons/ArrowDown.vue';
import { mixin as clickaway } from 'vue-clickaway';

export default {
  name: 'MobileScreensMenu',
  mixins: [clickaway],
  components: {
    // ArrowDown,
  },
  data() {
    return {
      currentScreenIndex: 0,
      isMobileMenuOpen: false,
    };
  },
  computed: {
    menuItems() {
      return [
        {
          menuTitle: this.$t("walletScreen.services.digitalDevice"),
          imgSrc: '/images/elements/screens/assets.png',
        },
        {
          menuTitle: this.$t("walletScreen.services.easyFollow"),
          imgSrc: '/images/elements/screens/transaction.png',
        },
        {
          menuTitle: this.$t("walletScreen.services.deposit"),
          imgSrc: '/images/elements/screens/balance.png',
        },
      ];
    },
    currentScreen() {
      return this.menuItems[this.currentScreenIndex].imgSrc;
    },
    selectedMenuItem() {
      return this.currentScreenIndex
        ? this.menuItems[this.currentScreenIndex].menuTitle
        : this.menuItems[0].menuTitle;
    },
  },
  methods: {
    setCurrentScreen(index) {
      this.currentScreenIndex = undefined;
      setTimeout(() => {
        this.currentScreenIndex = index;
      }, 200);
    },
    openMobileMenu() {
      this.isMobileMenuOpen = true;
    },
    selectItem(index) {
      this.currentScreenIndex = index;
      this.isMobileMenuOpen = false;
    },
    handleMobileMenuBlur() {
      this.isMobileMenuOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.module-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;

  @media screen and (max-width: 574px) {
    overflow: hidden;
    padding-bottom: 30px;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
}

.menu-list {
  list-style-type: none;
  margin: 0;
  padding: 0 0 75px;
  max-width: 600px;

  @media screen and (max-width: 1439px) {
    padding: 0 0 45px;
  }

  @media screen and (max-width: 1199px) {
    padding: 0 0 75px;
  }
}

.menu-item {
  cursor: pointer;
  font-family: "HKNova-Bold", sans-serif;
  font-size: 18px;
  line-height: 30px;
  border-radius: 0 10px 10px 0;
  height: 100px;
  display: flex;
  align-items: center;
  padding: 0 95px 0 120px;
  position: relative;
  transition: all 0.3s ease-in-out;
  border: 1px solid rgba(75, 46, 170, 0.2);
  border-left: 0;
  text-align: left;
  color: rgba(#262A52, 0.7);
  margin: 0 0 30px;

  &:last-child {
    margin: 0;
  }

  @media screen and (max-width: 1439px) {
    padding-left: 35px;
    font-size: 16px;
    line-height: 28px;
    height: 85px;
  }

  &:hover,
  &.active {
    border-color: #4B2EAA;
    color: #262A52;
  }
}

.mobile-wrapper {
  position: relative;
  width: 315px;
  height: 645px;

  @media screen and (max-width: 1439px) {
    width: 246px;
    height: 515px;
  }

  @media screen and (max-width: 1199px) {
    width: 275px;
    height: 560px;
  }

  @media screen and (max-width: 991px) {
    width: 375px;
  }

  @media screen and (max-width: 575px) {
    overflow: hidden;
    width: calc(100% + 60px);
    margin: 0 -30px;
    height: 650px;
    margin-top: 45px;
    margin-bottom: -140px;
  }

  .current-screen {
    position: absolute;
    max-width: 268px;
    z-index: 1;
    top: 60px;
    left: 110px;

    @media screen and (max-width: 1439px) {
      max-width: 214px;
      top: 70px;
      left: 34px;
    }

    @media screen and (max-width: 1199px) {
      left: 50px;
    }

    @media screen and (max-width: 767px) {
      left: 50%;
      transform: translateX(-50%);
    }

    @media screen and (max-width: 575px) {
      max-width: 204px;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.device-img {
  position: absolute;
  top: 48px;
  left: -5px;
  width: 500px;
  z-index: 1;
  max-width: none;

  @media screen and (max-width: 1439px) {
    width: 400px;
    left: -58px;
    top: 60px;
  }

  @media screen and (max-width: 1199px) {
    left: -42px;
  }

  @media screen and (max-width: 767px) {
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (max-width: 575px) {
    width: 380px;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
  }
}

.menu-wrapper {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.mobile-menu-wrapper {
  display: none;
  border-radius: 10px;
  margin: 0 0 40px;
  border: 1px solid rgba(75, 46, 170, 0.2);
  position: relative;
  max-width: 385px;

  @media screen and (max-width: 767px) {
    display: block;
    width: 100%;
  }

  @media screen and (max-width: 575px) {
    margin: 0;
  }

  .menu-list {
    position: absolute;
    top: 95px;
    left: -1px;
    overflow: hidden;
    max-height: 0;
    padding: 0;
    transition: all 0.4s ease-in-out;
    width: calc(100% + 2px);
    background: #fff;
    border: 1px solid transparent;
    border-top: 0;
    z-index: 2;
    border-radius: 0 0 10px 10px;

    &.opened {
      max-height: 1000px;
      border-color: rgba(75, 46, 170, 0.2);;
    }

    .menu-item {
      padding: 25px;
      border: 0;
      border-bottom: 1px solid rgba(75, 46, 170, 0.2);
      border-radius: 0;
      height: auto;
      margin: 0;

      &:last-child {
        border-bottom: 0;
      }

      &:after {
        left: 25px;
      }

      &:before {
        display: none;
      }
    }
  }

  .selected {
    height: 105px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 0 0 20px;

    .selected-text {
      font-family: "HKNova-Bold", sans-serif;
      color: #262A52;
      font-size: 18px;
      line-height: 30px;
      text-align: left;
      padding-right: 40px;
    }
  }

  .menu-icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    cursor: pointer;
    transition: all 0.4s ease-in-out;

    &.opened {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}
</style>
