<template>
  <div id="wallet-mobile-suggester">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="title headline-l">
            {{ $t("walletScreen.mobileSuggester.title") }}
          </div>
          <div class="description headline">
            {{ $t("walletScreen.mobileSuggester.description") }}
          </div>
          <!-- <div class="qr-code-wrapper">
            <img class="qr-code" src="/images/screens/wallet/qr.png" />
          </div> -->
          <div class="open-application text-l">
            {{ $t("walletScreen.mobileSuggester.openApplication") }}
          </div>
          <div class="d-flex justify-content-center align-center stores-list">
            <store-button :version="'apple'" />
            <store-button :version="'google'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WalletMobileSuggester",
  props: {},
  components: {},
  data: () => ({}),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
#wallet-mobile-suggester {
  padding-bottom: $margin-m;
  text-align: center;

  // .qr-code-wrapper {
  //   text-align: center;
  //   margin-bottom: 45px;
  //   @media (max-width: $breakpoint-sm) {
  //     display: none;
  //   }

  //   .label {
  //     display: block;
  //     margin: 0 0 30px;
  //   }

  //   .qr-code {
  //     margin: 0 auto;
  //   }
  // }

  .title {
    color: $primary-purple;
    font-weight: bold;
    text-align: center;
    margin-bottom: 45px;

    @media (max-width: $breakpoint-xs) {
      font-size: 29px;
      line-height: 1.3;
    }
  }
  .description {
    margin-bottom: 45px;
    font-weight: bold;
  }
  .open-application {
    margin-bottom: 15px;
    color: $text-blue;
  }
  @media (max-width: $breaking-point-to-sm) {
    //   .title {
    //     font-size: 27px;
    //   }
    //   .description {
    //     font-size: 18px;
    //   }
  }
}

.stores-list {
  button {
    @media (max-width: $breakpoint-xs) {
      display: flex;
      width: 50%;
      justify-content: center;
    }

    &:first-child {
      margin: 0 30px 0 0;

      @media (max-width: $breakpoint-xs) {
        margin: 0 20px 0 0;
      }
    }
  }
}
</style>
