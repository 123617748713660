<template>
  <div id="wallet-web-suggester">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="title headline-l">
            {{ $t("walletScreen.webSuggester.title") }}
          </div>
          <div class="description headline">
            {{ $t("walletScreen.webSuggester.description") }}
          </div>
          <div class="buttons-row">
            <base-button
              class="login d-lg-block"
              :type="'secondary'"
              :text="$t('globals.navigation.profile.login')"
              @clicked="handleNavbarBtnClick('login')"
            />
            <base-button
              class="registration"
              :text="$t('globals.navigation.profile.registration')"
              @clicked="handleNavbarBtnClick('registration')"
            />
          </div>
        </div>
        <div class="col-md-12 mb-4 mb-md-0 d-none d-md-block">
          <img
            :src="`/images/screens/wallet/desktop/laptop_en.jpg`"
            class="illustration"
          />
        </div>
        <div class="col-12 d-sm-none nopadding">
          <img
            :src="`/images/screens/wallet/mobile/laptop_en.jpg`"
            class="illustration"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WalletWebSuggester",
  props: {},
  components: {},
  data: () => ({}),
  created() {},
  computed: {},
  methods: {
    handleNavbarBtnClick(type) {
      if (type === "login") {
        window.open("https://wallet.blockben.com/login", "_blank");
      } else if (type === "registration") {
        window.open("https://wallet.blockben.com/register", "_blank");
      }
    }
  },
};
</script>

<style lang="scss" scoped>
#wallet-web-suggester {
  padding-bottom: $margin-m;
  text-align: center;
  .title {
    color: $primary-purple;
    font-weight: bold;
    text-align: center;
    margin-bottom: 15px;

    @media (max-width: $breakpoint-xs) {
      font-size: 29px;
      line-height: 1.3;
    }
  }
  .description {
    margin-bottom: 25px;
    font-weight: bold;
  }
  // @media (max-width: $breaking-point-to-sm) {
  //   .title {
  //     font-size: 27px;
  //   }
  //   .description {
  //     font-size: 18px;
  //   }
  // }
}

.buttons-row {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: center;
  column-gap: 20px;
  margin: 0 0 90px;
}
</style>
